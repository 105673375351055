import axios from "axios";
import config from "../utils/config";

export const createOrg = async (
  organisation_id,
  organisation_name,
  first_name,
  last_name,
  email_id
) => {
  const baseURL = config.API_URL;
  const path = "/organisation";
  // const parameters = "?organisation_id=" + org_id;

  const params = {
    organisation_id: organisation_id,
    organisation_name: organisation_name,
    first_name: first_name,
    last_name: last_name,
    email_id: email_id,
  };

  console.log("creating organisation with the params   - ", params);

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};

export const readOrg = async (organisation_id) => {
  const baseURL = config.API_URL;
  const path = "/organisation" + "?organisation_id=" + organisation_id;

  console.log("getting org details with the params   - ", organisation_id);

  const data = await axios.get(`${baseURL + path}`);

  return data;
};

export const getOrgDetails = async (organisation_id) => {
  const baseURL = config.API_URL;
  const path = "/organisation";
  const parameters = "?organisation_id=" + organisation_id;
  const response = await axios.get(`${baseURL + path + parameters}`);

  return response;
};

export const getPricingUsage = async (organisation_id) => {
  const baseURL = config.API_URL;
  const path = "/pricing_usage";
  const parameters = "?organisation_id=" + organisation_id;
  const response = await axios.get(`${baseURL + path + parameters}`);

  return response;
};
