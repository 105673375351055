import React, { useState, useCallback, useEffect, useMemo } from "react";
import "./ManualUpload.css";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import isValidFilename from "valid-filename";
import { generate_jwt_token } from "../../api/jwt";
import Dropzone from 'react-dropzone'
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { readOrg } from "../../api/organisation";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import CircularProgress from '@mui/material/CircularProgress';

export default function ManualUpload() {
  // update here to get this data

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [access_key, set_access_key] = useState();

  const [secret_access_key, set_secret_access_key] = useState();

  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );
  let cognitoUserId = user.username;

  const getOrgDetailsHelper = async () => {
    const res = await readOrg(cognitoUserId);
    set_access_key(res?.data?.[0]?.api_key);
    set_secret_access_key(res?.data?.[0]?.api_secret);
  };

  useEffect(() => {
    getOrgDetailsHelper();
  }, []);

  const [videoId, setVideoId] = useState(crypto.randomUUID());
  const [video, setVideo] = useState(null);
  const [resume, setResume] = useState(null);
  const [jobDescription, setJobDescription] = useState(null);
  const [qna, setQna] = useState(null);
  const [video_type, set_video_type] = useState("interview_india");
  const [file_metadata, set_file_metadata] = useState([]);

  const [responsedata, setResponsedata] = useState(null);
  const [title, setTitle] = useState("");
  const [progress, setProgress] = useState({});

  const videoExtensions = ["mp4", "webm", "mov"]
  const textExtensions = ["pdf", "csv", "xls", "xlsx", "docx", "txt"];

  const [videoError, setVideoError] = useState(null);
  const [resumeError, setResumeError] = useState(null);
  const [jobDescriptionError, setJobDescriptionError] = useState(null);
  const [qnaError, setQnaError] = useState(null);

  const handleVideoUpload = async () => {
    setLoading(true);
    setResponsedata(null);

    const config = {
      headers: {
        Authorization: generate_jwt_token(access_key, secret_access_key),
      },
    };

    const files = [video, resume, jobDescription, qna];

    let fileCount = 0
    let uploadedFileCount = 0
    for (let i = 0; i < files.length; ++i) {

      if (files[i] == null) {
        continue;
      }

      fileCount += 1;

      const bodyParameters = {
        video_id: videoId,
        video_type: video_type,
        video_title: title,
        file_type: files[i].file_type,
        file_name: files[i].file.name,
        file_format: files[i].file.name.split('.').pop(),
      };

      try {
        const result = await axios.post(
          "https://api.imbesideyou.world/file",
          bodyParameters,
          config
        );

        const presignedS3Url = result.data.pre_signed_url;
        const configPut = {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(prev => ({
              ...prev,
              [files[i].file.name]: percentCompleted
            }));
          },
          headers: {
            'Content-Type': files[i].file.type,
          }
        };

        const axiosResponse = await axios.put(presignedS3Url, files[i].file, configPut);

        if (axiosResponse.status === 200) {
          uploadedFileCount += 1;
        }

      } catch (error) {
        console.error(error);
        setError(true);
      }
      // setResponsedata(result);
    }
    setLoading(false);


    if (uploadedFileCount == fileCount) {
      setResponsedata("uploadSuccess")
      setSuccess(true);
    }
  };

  const onDropVideo = useCallback((acceptedFiles) => {
    console.log("onDropVideo");

    setVideoError(null);

    const fileExtension = acceptedFiles[0].name.split('.').pop();
    if (!videoExtensions.includes(fileExtension)) {
      setVideoError("Invalid file extension: " + fileExtension + ". Allowed extensions are: " + videoExtensions.join(", "));
      return;
    }

    setVideo({ "file_type": "video", "file": acceptedFiles[0] });
    setTitle(acceptedFiles[0].name)
    setProgress((prevState) => ({ ...prevState, [acceptedFiles[0].name]: 0 }));

  }, []);
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onDropResume = useCallback((acceptedFiles) => {
    console.log("onDropResume");

    setResumeError(null);

    const fileExtension = acceptedFiles[0].name.split('.').pop();
    if (!textExtensions.includes(fileExtension)) {
      setResumeError("Invalid file extension: " + fileExtension + ". Allowed extensions are: " + textExtensions.join(", "));
      return;
    }

    setResume({ "file_type": "resume", "file": acceptedFiles[0] });
    set_video_type("interview_india_vs_zip");
    set_file_metadata((file_metadata => [...file_metadata.filter(metadata => metadata.file_type !== "resume"), { file_type: "resume", file_name: acceptedFiles[0].name, file_format: acceptedFiles[0].name.split('.').pop() }]));
    setProgress((prevState) => ({ ...prevState, [acceptedFiles[0].name]: 0 }));

  }, []);

  const onDropJobDescription = useCallback((acceptedFiles) => {
    console.log("onDropJobDescription");

    setJobDescriptionError(null);

    const fileExtension = acceptedFiles[0].name.split('.').pop();
    if (!textExtensions.includes(fileExtension)) {
      setJobDescriptionError("Invalid file extension: " + fileExtension + ". Allowed extensions are: " + textExtensions.join(", "));
      return;
    }

    setJobDescription({ "file_type": "job_description", "file": acceptedFiles[0] });
    set_video_type("interview_india_vs_zip");
    set_file_metadata((file_metadata => [...file_metadata.filter(metadata => metadata.file_type !== "job_description"), { file_type: "job_description", file_name: acceptedFiles[0].name, file_format: acceptedFiles[0].name.split('.').pop() }]));
    setProgress((prevState) => ({ ...prevState, [acceptedFiles[0].name]: 0 }));
  }, []);

  const onDropQna = useCallback((acceptedFiles) => {
    console.log("onDropQna");

    setQnaError(null);

    const fileExtension = acceptedFiles[0].name.split('.').pop();
    if (!textExtensions.includes(fileExtension)) {
      setQnaError("Invalid file extension: " + fileExtension + ". Allowed extensions are: " + textExtensions.join(", "));
      return;
    }

    setQna({ "file_type": "qna", "file": acceptedFiles[0] });
    set_video_type("interview_india_vs_zip");
    set_file_metadata((file_metadata => [...file_metadata.filter(metadata => metadata.file_type !== "qna"), { file_type: "qna", file_name: acceptedFiles[0].name, file_format: acceptedFiles[0].name.split('.').pop() }]));
    setProgress((prevState) => ({ ...prevState, [acceptedFiles[0].name]: 0 }));

  }, []);

  const baseStyle = {
    padding: "1rem",
    width: "100%",
    height: "10rem",
    textAlign: "center",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  // const [video_type, set_video_type] = useState("interview_india");

  return (
    <div className="manual-upload-wrapper">

      <div className="manual-upload-sub-wrapper">
        <div
          className="manual-upload-upload-wrapper"
          style={{ overflow: "scroll" }}
        >
          <div className="files-upload">
            <div className="video-upload">
              <b>Video</b>
              <Dropzone onDrop={acceptedFiles => onDropVideo(acceptedFiles)}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps({ padding: 0 })} />
                      {resume === null ? (
                        isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <p>Drag 'n' drop a video here (only <strong>{videoExtensions.join(', ')}</strong> files are allowed), or click to select files</p>
                        )
                      ) : (
                        <p>Video selected</p>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <p style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
                <span style={{ color: "purple" }}>{video?.file.name}</span>
                {video !== null ? (
                  <div className="upload-footer">
                    <span className="upload-file-name">
                      ...................{" "}
                      <RxCrossCircled
                        className="remove-file"
                        onClick={() => {
                          setVideo(null);
                          // setResponsedata(null);
                        }}
                      />
                    </span>
                    <CircularProgress className="upload-progress" variant="determinate" value={progress[video?.file.name]} />
                  </div>
                ) : null}
              </p>
              {videoError && <p className="error-message">{videoError}</p>}
            </div>

            <div className="resume-upload">
              <b>Resume</b>
              <Dropzone onDrop={acceptedFiles => onDropResume(acceptedFiles)}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps({ padding: 0 })} />
                      {resume === null ? (
                        isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <p>Drag 'n' drop a resume here (only <strong>{textExtensions.join(', ')}</strong> files are allowed), or click to select files</p>
                        )
                      ) : (
                        <p>Resume selected</p>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <p style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
                <span style={{ color: "purple" }}>{resume?.file.name}</span>
                {resume !== null ? (
                  <div className="upload-footer">
                    <span className="upload-file-name">
                      ...................{" "}
                      <RxCrossCircled
                        className="remove-file"
                        onClick={() => {
                          setResume(null);
                          // setResponsedata(null);
                          const updatedFileMetadata = file_metadata.filter(metadata => metadata.file_type !== "resume");
                          set_file_metadata(updatedFileMetadata);
                        }}
                      />
                    </span>
                    <CircularProgress className="upload-progress" variant="determinate" value={progress[resume?.file.name]} />
                  </div>
                ) : null}
              </p>
              {resumeError && <p className="error-message">{resumeError}</p>}
            </div>

            <div className="jd-upload">
              <b>Job Description</b>
              <Dropzone onDrop={acceptedFiles => onDropJobDescription(acceptedFiles)}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps({ padding: 0 })} />
                      {jobDescription === null ? (
                        isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <p>Drag 'n' drop a job description here (only <strong>{textExtensions.join(', ')}</strong> files are allowed), or click to select files</p>
                        )
                      ) : (
                        <p>job description selected</p>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <p style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
                <span style={{ color: "purple" }}>{jobDescription?.file.name}</span>
                {jobDescription !== null ? (
                  <div className="upload-footer">
                    <span className="upload-file-name">
                      ...................{" "}
                      <RxCrossCircled
                        className="remove-file"
                        onClick={() => {
                          setJobDescription(null);
                          // setResponsedata(null);
                          const updatedFileMetadata = file_metadata.filter(metadata => metadata.file_type !== "job_description");
                          set_file_metadata(updatedFileMetadata);
                        }}
                      />
                    </span>
                    <CircularProgress className="upload-progress" variant="determinate" value={progress[jobDescription?.file.name]} />
                  </div>
                ) : null}
              </p>
              {jobDescriptionError && <p className="error-message">{jobDescriptionError}</p>}
            </div>

            <div className="qna-upload">
              <b>QnA</b>
              <Dropzone onDrop={acceptedFiles => onDropQna(acceptedFiles)}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps({ padding: 0 })} />
                      {qna === null ? (
                        isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <p>Drag 'n' drop a QnA here (only <strong>{textExtensions.join(', ')}</strong> files are allowed), or click to select files</p>
                        )
                      ) : (
                        <p>QnA selected</p>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <p style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
                <span style={{ color: "purple" }}>{qna?.file.name}</span>
                {qna !== null ? (
                  <div className="upload-footer">
                    <span className="upload-file-name">
                      ...................{" "}
                      <RxCrossCircled
                        className="remove-file"
                        onClick={() => {
                          setQna(null);
                          // setResponsedata(null);
                          const updatedFileMetadata = file_metadata.filter(metadata => metadata.file_type !== "qna");
                          set_file_metadata(updatedFileMetadata);
                        }}
                      />
                    </span>
                    <CircularProgress className="upload-progress" variant="determinate" value={progress[qna?.file.name]} />
                  </div>
                ) : null}
              </p>
              {qnaError && <p className="error-message">{qnaError}</p>}
            </div>

          </div>
        </div>

        <div style={{ marginLeft: "1rem", height: "60vh" }}>
          <b>Request Body</b>
          <br></br>
          <JSONInput
            id="a_unique_id"
            locale={locale}
            height="100%"
            placeholder={{
              video_type: video_type,
              file_name: video?.name,
              video_title: title,
              file_metadata: file_metadata,
            }}
          />
        </div>
      </div>


      <button
        className={`manual-upload-button ${loading
          ? "loading"
          : success
            ? "success"
            : error
              ? "error"
              : "" // ボタンの状態に応じたCSSクラスを適用
          }`}
        style={
          !isValidFilename(video?.file.name.replace(".mp4", "")) || video === null
            ? { opacity: "0.5", cursor: "not-allowed" }
            : {}
        }
        disabled={
          !isValidFilename(video?.file.name.replace(".mp4", "")) || video === null
        }
        onClick={() => {
          handleVideoUpload();
        }}
      >
        {loading ? "Uploading..." : success ? "Saved" : error ? "Error" : "Upload"}
      </button>


      {responsedata !== null ? (
        <p>
          Video: successfully uploaded {"\n"} video_id:{" "}
          {videoId}
        </p>
      ) : null}
    </div>
  );
}
