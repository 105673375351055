import React from "react";
import "./ApiReference.css";
import { AiOutlineReload } from "react-icons/ai";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsCloudUploadFill } from "react-icons/bs";
import { IoReloadCircle } from "react-icons/io5";
const ApiReference = () => {
  const ducumentationData = [
    {
      apiHeading: "Upload Video",
      apiDesc: "To upload video for analysis call this endpoint.",
      httpRequestCode: "POST https://api.imbesideyou.world/video",
      parameters: [],
      requestBodyDesc:
        "In the request body, supply video_type along with video_title, to get a presigned url which can be used to upload your video.",
      requestProperties: [
        {
          propertyName: "video_type (required)",
          datatype: "string",
          desc: `Type of analysis required. 
Please put 'interview_analysis' for basic analysis.
Please put 'interview_analysis_zip_vs' for ovearall analysis (zip file should be uploaded)`,
        },
        {
          propertyName: "file_name (required)",
          datatype: "string",
          desc: "Filename with extension (ex: 'test.mp4') of video.",
        },
        {
          propertyName: "video_title",
          datatype: "string",
          desc: "Title of video for reference. Default: file_name without extension",
        },
        {
          propertyName: "file_metadata",
          datatype: "array of objects",
          desc: `(required if sending zip file for video_type: interview_analysis_zip_vs) 
Detail about files present in the zip file as shown below.
[
    {
        file_type: "resume"/ "job_description"/ "video"/ "qna",
        file_name: Name of file (string),
        file_format: "pdf"/ "csv"/ "mp4/ "webm" etc
    }
]`,
        },
      ],
      responseDesc: "If successful, this method returns an object body.",
      responsePreoperties: [
        {
          propertyName: "pre_signed_url",
          datatype: "string",
          desc: "Url to upload video",
        },
        {
          propertyName: "video_id",
          datatype: "string",
          desc: "video_id for uploaded video",
        },
      ],
      helperDesc:
        "Here's an helper code to create jwt token in python and javascript.",
      helperCode: `
        #Python:
        import time 
        import base64
        import hmac
        import hashlib
        def get_jwt_token(access_key, secret_access_key):
            expiration = int(time.time()) + 1000
            
            header = base64.urlsafe_b64encode(
                '{"alg":"HS256","typ":"JWT"}'.encode()).replace(b'=', b'')
                
            payload = base64.urlsafe_b64encode(
                ('{"iss":"'+access_key+'","exp":"'+str(expiration)+'"}').encode()).replace(b'=', b'')
                
            hashdata = hmac.new(secret_access_key.encode(), header +
                                ".".encode()+payload, hashlib.sha256)
                                
            signature = base64.urlsafe_b64encode(hashdata.digest()).replace(b'=', b'')
            
            token = (header+".".encode()+payload+".".encode()+signature).decode()
            
            print(token)
            return token
            
            
        #Javascript:
        const CryptoJS = require("crypto-js");

        const base64url = (source) => {
          let encodedSource = CryptoJS.enc.Base64.stringify(source);
          encodedSource = encodedSource.replace(/=+$/, "");
          encodedSource = encodedSource.replace(/\+/g, "-");
          encodedSource = encodedSource.replace(/\//g, "_");
          return encodedSource;
        };

        const createJWT = (header, data, secret = undefined) => {
          let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
          let encodedHeader = base64url(stringifiedHeader);
          let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
          let encodedData = base64url(stringifiedData);
          let token = encodedHeader + "." + encodedData;
          if (!secret) return token;
          let signature = CryptoJS.HmacSHA256(token, secret);
          signature = base64url(signature);

          console.log(signature);
          console.log(encodedHeader + "." + encodedData + "." + signature);
          return encodedHeader + "." + encodedData + "." + signature;
        };

        export const generate_jwt_token = (access_key, secret_access_key) => {
          let header = { alg: "HS256", typ: "JWT" };

          let expiration = new Date().getTime() / 1000 + 1000;

          let data = {
            iss: access_key,
            exp: expiration,
          };

          let token = createJWT(header, data, secret_access_key);

          let output = 'Bearer ' + token;
          
          console.log(output);

          return output;
        };

        # APi call in javascript
        const handleVideoUpload = async () => {

          const config = {
            headers: {
              Authorization: generate_jwt_token(access_key, secret_access_key),
            },
          };

          const bodyParameters = {
            video_type: "interview_india",
            video_title: title,
            file_name: filename,
            file_metadata: file_metadata,
          };

          const result = await axios.post(
            "https://api.imbesideyou.world/video",
            bodyParameters,
            config
          );

          const presignedS3Url = result.data.pre_signed_url;

          const axiosResponse = await axios.put(presignedS3Url, video, {
            headers: {
              "Content-Type": video.type,
            },
        });
        `,
      examleDesc: "Here is sample request and response",
      exampleCode: `
        #Request 
        api call: https://api.imbesideyou.world/video

        # for video_type: interview_india
        body: {
            "video_type": "interview_india",
            "video_title": "test",
            "file_name": "test.webm"
        }

        # for video_type: interview_analysis_zip_vs
        body: {
            "video_type": "interview_india_vs_overall_zip",
            "video_title": "test for zip and overall analysis",
            "file_name": "test_for_overall.zip",
            "file_metadata": [
                {
                    "file_type": "resume",
                    "file_name": "resume.pdf",
                    "file_format": "pdf"
                },
                {
                    "file_type": "job_description",
                    "file_name": "job_description.csv",
                    "file_format": "csv"
                },
                {
                    "file_type": "video",
                    "file_name": "test_candidate.mp4",
                    "file_format": "mp4"
                }
            ]
        }

        response: {
          "pre_signed_url": "https://interview-analysis-india.s3.amazonaws.com/b1a37d5a-
            4031-704a-6cc4-73816d003b6f/interview_india/9d7068fe-87cb-438a-b6c5-d361a242
            a38a/test.webm?X-Amvvcz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAXKNS
            HYQGTFWAYFDWA2WN%2F20230913%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=2023
            0913T111321Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token
            =IQoJb3JpZ2luX2VjEEMaCmFwLXNvdXRoLTEiRzBFAiEAxlvAFQfyu%2BYayebFGHx3VaY5fY%2FV
            lXg6fi%2FVTQsdSBsCICgvwvjsIoY8gd1pFkvKxWfyLFxTyRr464eIuzuCj34UKqADCCwQARoMNTA
            zNDIxODQ2NTQxIgyG0PmY5wWp1BkKmUMq%xx2FQIebWLhwRq8BAws7ouA4Yvuk8wj6fYYIwjhdbcdjcd
            scmVjyAf3lpqXdb24be4OfqwEHzGXmFDB%2BaFM8YLiTC27M3JfHEYqu8ukpdVdgce8uoOnSvdMETw
            nyHgHb8FYshTLg7YkDrPlyY5UUcTYHGw5DQGlXywjl0KI0nEbedmdioVqVc0Z0vv9fXdzalgEYHqak
            ohPqCF0tW%2FmQKGvBdIROztHeO79Yf60rOciw2gv8UiI2a1B3lK1aCD%2BJ1SInWkcxD%2FMAc21r
            3MBmG2ofjV%2BHD2MF2xx2BbuIEjSuPzYLCmmUHHvakXXFDiKG%2FST%2FPpbKuCUm5lJbWhLpn
            vS3UMwr5Rh0T636iaSU1ZmMFXakqfe%2Fi2hm6D9IcsTtXbj9yLoXijuwZ%2FAikI3Vdr8Oh1%2B2Y
            yw3xUxLQoYhJzFJIIro5v80DRTp5a%2BFtcG3QkoXeCUaucjJRUiHPIyI5nfQJDxGpj%2B566DXTMwK
            3%2BrxiLmRAI3a44NDLo2RtDno1ImnbkRImdkwrbKGqAY6nQErn4Km6fry6Iuxl%2FJ9xZxkqo2mwcD
            uyaK4mGLVJST%2FTOzVnAZDUozVLl%2BaoHqj5E87%2BBa2pcGB1zSkJ2ZcfYqUAWauLKNBo06YaVG2
            r61v2OI1HbmoCmnzImck2WNJfYI6EEFxwR3cA4hgCkFwDOY7mXF5o1sNRp5NMpfIRB2H2Txi3BnoZTB
            9XAJXtF6Pzuyadm9ygfwJKdz7LvWm&X-Amz-Signature=bfb7cec3ac675d531e82aa71692469db7
            47b57141a51f6ecf31293c9bf47498f",
          "video_id": "9d7068fe-87cb-438a-b6c5-d361a242a38a"
        }
      `,
    },
    {
      apiHeading: "Get video analysis",
      apiDesc: "To get analysis of uploaded video call this endpoint.",
      httpRequestCode: "GET https://api.imbesideyou.world/analysis",
      parameters: [
        {
          parameterName: "video_id",
          datatype: "string",
          desc: "To get detailed analysis result of one video. Don't add video_id in url param if you need overview of all videos",
        },
      ],
      requestBodyDesc: "Body not needed.",
      requestProperties: [],
      responseDesc:
        "If successful, this method returns an object body. If analysis is successful then all parameters except presigned_url and remarks are present. When analysis failed due to reasons like file corruption or non matching files etc then only presigned_url and remarks are present in response",
      responsePreoperties: [
        {
          propertyName: "video_list",
          datatype: "array of objects",
          desc: "(Only present when fetching for all videos) array of objects containing list of {video_id, video_type, creation_date, video_title, video_analysis_status, audio_analysis_status, transcript_analysis_status, final_analysis_status, video_segments_status, overall_analysis_status, duration_in_sec}",
        },
        {
          propertyName: "video_metadata",
          datatype: "array of objects",
          desc: "array of objects containing list of {video_id, video_type, creation_date, video_title, video_analysis_status, audio_analysis_status, transcript_analysis_status, final_analysis_status, video_segments_status, overall_analysis_status, duration_in_sec} for requested video",
        },
        {
          propertyName: "gaze",
          datatype: "array of objects",
          desc: 'array of objects containing list of {video_id, time_in_seconds, blink, gaze, eye_offset} for requested video. If the gaze was directed forward, "gaze" is 1. If it deviated, it is 0. If there was a blink, "blink" is 1; if there was no blink, it is 0. "eye_offset" is a value indicating the degree of gaze deviation, ranging from -180 to 180. The closer the absolute value is to 0, the more it indicates that the gaze is directed forward.',
        },
        {
          propertyName: "face_emotions",
          datatype: "array of objects",
          desc: "array of objects containing list of {video_id, time_in_seconds, angry, disgust, fear, happy, sad, surprise, neutral} for requested video",
        },
        {
          propertyName: "transcript_analysis",
          datatype: "array of objects",
          desc: "array of objects containing list of {video_id, seek, start, end, text, positive, negative, neutral, confidence, hesitant, concise, enthusiasm, speech_speed} for requested video",
        },
        {
          propertyName: "voice_emotion_lines",
          datatype: "array of objects",
          desc: "array of objects containing list of {video_id, time_in_seconds, angry, disgust, fear, happy, sad, surprise, neutral} for requested video",
        },
        {
          propertyName: "interval_analysis",
          datatype: "array of objects",
          desc: "array of objects containing list of {start_time (float), end_time (float), title (string), transcript (string), insight (string)} for requested video",
        },
        {
          propertyName: "overall_analysis",
          datatype: "array of objects",
          desc: "array of objects containing list of {analysis_type (string), analysis_title (string), analysis_result (string)} for requested video \
          analysis title includes: (string)\
          Technical Skills (string)\
          Soft skills (string)\
          Suggested Designation (string)\
          Fit for the Job role (string)\
          Matching Percentage (string)\
          Reason for Match (string)\
          Experience Level (string)\
          Relevant answers to the questions asked or not (string)",
        },
        {
          propertyName: "strengths",
          datatype: "array of objects",
          desc: "list of strengths of candidate in requested video",
        },
        {
          propertyName: "weaknesses",
          datatype: "array of objects",
          desc: "list of weaknesses of candidate in requested video",
        },
        {
          propertyName: "follow_up_questions",
          datatype: "array of objects",
          desc: "list of follow_up_questions by candidate in requested video",
        },
        {
          propertyName: "video_tags",
          datatype: "array of objects",
          desc: "each object contains a key 'tag' and a tag as value. \n\
tags currently available: \
            fear, \
            Nervous, \
            Not Nervous, \
            Not suspicious, \
            Suspicious, \
            Less confident, \
            Highly confident, \
            Moderately confident, \
            Less Enthusiastic, \
            Highly Enthusiastic, \
            Moderately Enthusiastic, \
            Less Positive, \
            Highly Positive, \
            Moderately Positive, \
            calm",
        },
        {
          propertyName: "scores",
          datatype: "object",
          desc: "object containing params: positive_facial_emotion, \
            negative_facial_emotion,	\
            neutral_facial_emotion,	\
            facial_nervousness,	\
            eye_contact,	\
            positive_sentiment,	\
            negative_sentiment,	\
            neutral_sentiment,	\
            confidence,	\
            hesitant,	\
            enthusiasm,	\
            positive_tone, \
            negative_tone, \
            neutral_tone,	\
            calmness for requested video",
        },
        {
          propertyName: "presigned_url",
          datatype: "string",
          desc: "(Present in response only when analysis failed) Contains a url to upload files again for same interview.",
        },
        {
          propertyName: "remarks",
          datatype: "string",
          desc: `(Present in response only when analysis failed) Contains reason why the analysis failed. 
(ex: "file could not be processed, please upload again")`,
        },
      ],
      examleDesc: "Here is sample request and response",
      exampleCode: `
        #Request to get detail data of one video
        api call: https://api.imbesideyou.world/analysis?video_id=c315eabb-33f9-48e3-b16e-76324add3d03

        response: {
          "video_metadata": [
            {
              "video_id": "c315eabb-33f9-48e3-b16e-76324add3d03",
              "video_type": "interview_india",
              "creation_date": "2023-09-08 15:51:24",
              "video_title": "test video",
              "video_analysis_status": "processed",
              "audio_analysis_status": "processed",
              "transcript_analysis_status": "processed",
              "final_analysis_status": "processed",
              "duration_in_sec": 9,
              "file_name": "test_video.webm",
              "uploaded": 1
            }
          ],
          "gaze": [
            {
              "video_id": "c315eabb-33f9-48e3-b16e-76324add3d03",
              "time_in_seconds": 0,
              "blink": 0,
              "gaze": 0,
              "eye_offset": "56.6588"
            },
            {
              "video_id": "c315eabb-33f9-48e3-b16e-76324add3d03",
              "time_in_seconds": 0,
              "blink": 0,
              "gaze": 0,
              "eye_offset": "56.4435"
            },
          ],
          "face_emotions": [
            {
              "video_id": "c315eabb-33f9-48e3-b16e-76324add3d03",
              "time_in_seconds": 0,
              "angry": 0.232702,
              "disgust": 0.00000927639,
              "fear": 95.1354,
              "happy": 0.253081,
              "sad": 4.18745,
              "surprise": 0.00227806,
              "neutral": 0.189101,
            },
            {
              "video_id": "c315eabb-33f9-48e3-b16e-76324add3d03",
              "time_in_seconds": 0,
              "angry": 40.7693,
              "disgust": 0.015923,
              "fear": 12.2382,
              "happy": 0.0190685,
              "sad": 33.7566,
              "surprise": 0.00999593,
              "neutral": 13.1909
            },
          ],
          "transcript_analysis": [
            {
              "video_id": "c315eabb-33f9-48e3-b16e-76324add3d03",
              "start": 4,
              "end": 6,
              "text": " It was mocking you!",
              "positive": 0.00868378,
              "negative": 0.982996,
              "neutral": 0.00832045,
              "confidence": 0.000374075,
              "hesitant": 0.861608,
              "concise": 0.102851,
              "enthusiasm": 0.000417704,
              "speech_speed": 2
            },
            {
              "video_id": "c315eabb-33f9-48e3-b16e-76324add3d03",
              "start": 2,
              "end": 3,
              "text": " Everybody was mocking me.",
              "positive": 0.00602642,
              "negative": 0.983728,
              "neutral": 0.010246,
              "confidence": 0.000807896,
              "hesitant": 0.90314,
              "concise": 0.279659,
              "enthusiasm": 0.00413578,
              "speech_speed": 4
            },
          ],
          "voice_emotion_lines": [
            {
              "video_id": "c315eabb-33f9-48e3-b16e-76324add3d03",
              "start_time": 0,
              "end_time": 6,
              "neutral_score": 0,
              "calm_score": 0,
              "happy_score": 0,
              "sad_score": 0,
              "angry_score": 0,
              "fear_score": 0,
              "disgust_score": 100,
              "surprise_score": 0
            },
            {
              "video_id": "c315eabb-33f9-48e3-b16e-76324add3d03",
              "start_time": 3,
              "end_time": 9,
              "neutral_score": 0,
              "calm_score": 0,
              "happy_score": 0,
              "sad_score": 0,
              "angry_score": 0,
              "fear_score": 0,
              "disgust_score": 100,
              "surprise_score": 0
            },
          ],
          "video_tags": [
            {
              "tag": "suspicious"
            },
            {
              "tag": "confidence"
            }
          ],
          "interval_analysis": [
            {
              "start_time": 84.92,
              "end_time": 90.16,
              "title": "Engagement in Coding Clubs",
              "transcript": "actively engaged with coding clubs and parties created in hackathons where I had the opportunity",
              "insight": "The candidate expresses enthusiasm and confidence in their engagement with coding clubs and hackathons, as indicated by high positive and enthusiastic scores."
            },
            {
              "start_time": 67,
              "end_time": 77.74,
              "title": "Working on Concepts",
              "transcript": "concepts such as working on the concepts, L2 web scrapping and map mapping in your",
              "insight": "The candidate appears hesitant and not confident when discussing working on concepts, as indicated by low confident and hesitant scores."
            },
            {
              "start_time": 60.52,
              "end_time": 67,
              "title": "Exploring Programming Languages",
              "transcript": "I explored various programming languages including Java, JavaScript and delved into advanced",
              "insight": "The candidate demonstrates a positive and confident attitude towards exploring different programming languages."
            },
            {
              "start_time": 6.48,
              "end_time": 11.12,
              "title": "Thrilling Adventure",
              "transcript": "creation and majoring into the world of sub-pro development has been a thrilling adventure and",
              "insight": "The candidate expresses enthusiasm and confidence in their experience, as indicated by high positive and enthusiastic scores."
            },
            {
              "start_time": 113.64,
              "end_time": 120.64,
              "title": "Hands-on Experience",
              "transcript": "developer and I have been fortunate to accumulate a year of hands on experience in the field.",
              "insight": "The candidate expresses confidence and conciseness in their hands-on experience, as indicated by high confident and concise scores."
            }
          ],
          "overall_analysis": [
            {
              "analysis_type": "general",
              "analysis_title": "Fit for the job role",
              "analysis_result": "Yes"
            },
            {
              "analysis_type": "general",
              "analysis_title": "Suggested Designation",
              "analysis_result": "Software Developer"
            },
            {
              "analysis_type": "general",
              "analysis_title": "Experience level",
              "analysis_result": "Experienced candidate"
            },
            {
              "analysis_type": "general",
              "analysis_title": "Reason for match",
              "analysis_result": "The candidate has experience as a sub-pro developer and possesses skills that align with the requirements of a software developer role."
            },
            {
              "analysis_type": "technical_skills",
              "analysis_title": "Technical Skills",
              "analysis_result": "[JavaScript, HTML, CSS, React.js, Node.js, Angular.js, MongoDB, AWS, Java]"
            },
            {
              "analysis_type": "soft_skills",
              "analysis_title": "Soft skills",
              "analysis_result": "[Adaptability, Collaboration, Results-oriented, Time management, Problem-solving]"
            },
            {
              "analysis_type": "suggested_designation",
              "analysis_title": "Suggested Designation",
              "analysis_result": "Web Developer"
            },
            {
              "analysis_type": "fit_for_the_job_role",
              "analysis_title": "Fit for the Job role",
              "analysis_result": "Yes"
            },
            {
              "analysis_type": "matching_percentage",
              "analysis_title": "Matching Percentage",
              "analysis_result": "80"
            },
            {
              "analysis_type": "experience_level",
              "analysis_title": "Experience Level",
              "analysis_result": "Fresher"
            },
            {
              "analysis_type": "relevant_answers_to_the_questions_asked_or_not",
              "analysis_title": "Relevant answers to the questions asked or not",
              "analysis_result": "Yes"
            },
            {
              "analysis_type": "general",
              "analysis_title": "Matching Percentage",
              "analysis_result": "80"
            },
            {
              "analysis_type": "reason_for_match",
              "analysis_title": "Reason for Match",
              "analysis_result": "The candidate mentions proficiency in web development and data integration, which aligns with the skills required for the job role."
            }
          ], 
          "scores": {
            "positive_facial_emotion": 8.379855397730768,
            "negative_facial_emotion": 73.11290123788231,
            "neutral_facial_emotion": 18.507237660576923,
            "facial_nervousness": 53.941069059789974,
            "eye_contact": 61.53846153846154,
            "positive_sentiment": 21.895869428571427,
            "negative_sentiment": 73.98797285714286,
            "neutral_sentiment": 4.116156999999999,
            "confidence": 5.6843171,
            "hesitant": 77.2739,
            "enthusiasm": 8.635091914285715,
            "positive_tone": 0,
            "negative_tone": 66.66666666666667,
            "neutral_tone": 0,
            "calmness": 0
          }
        }

        #Request to get status of latest videos
        api call: https://api.imbesideyou.world/analysis

        response: {
          "video_list": [
            {
              "video_id": "6fe262de-9249-4fe8-8887-d7141b1da4dc",
              "video_type": "interview_india",
              "creation_date": "2023-09-12 19:51:30",
              "video_title": "test 20",
              "video_analysis_status": "processed",
              "audio_analysis_status": "processed",
              "transcript_analysis_status": "processed",
              "final_analysis_status": "pending",
              "duration_in_sec": 1129.26,
              "file_name": "d1449d5a-dccb-4e69-b3b2-a243a1a54840_3732_av.webm",
              "uploaded": 1
            },
            {
              "video_id": "bff449e3-a5f7-4ebf-90f3-21f47b7b2758",
              "video_type": "interview_india",
              "creation_date": "2023-09-12 19:01:52",
              "video_title": "test 19",
              "video_analysis_status": "processing",
              "audio_analysis_status": "processed",
              "transcript_analysis_status": "processed",
              "final_analysis_status": "pending",
              "duration_in_sec": 1129.26,
              "file_name": "d1449d5a-dccb-4e69-b3b2-a243a1a54840_3732_av.webm",
              "uploaded": 1
            },
          ]
        }

        `,
      helperDesc: "Here are some helper code",
      helperCode: `
        const handleGetVideoLogs = async (access_key, secret_access_key) => {
          const config = {
            headers: {
              Authorization: generate_jwt_token(access_key, secret_access_key),
            },
          };

          const result = await axios.get(
            "https://api.imbesideyou.world/analysis",
            config
          );

          console.log(result);

          setResponsedata(result);
        };`,
    },
  ];

  return (
    <div className="api-reference-root">
      <div className="api-reference-wrapper">
        {/* <span className="heading-text">Api Endpoints</span> */}

        {ducumentationData.map((data) => (
          <>
            <span className="heading-text code-text">{data.apiHeading}</span>
            <span> {data.apiDesc}</span>
            <span className=" bold-text">Request</span>
            <div className="documentation-section">
              <span className="bolder-text">HTTP Request</span>
              <div className="code-block">
                <span className="code-text">{data.httpRequestCode}</span>
              </div>
            </div>
            <div className="documentation-section">
              <span className="bolder-text">Parameters</span>
              <div className="table-block">
                {data.parameters.map((parameterData) => (
                  <div className="table-row">
                    <div className="table-entry-one">
                      <span className="code-text">
                        {parameterData.parameterName}
                      </span>
                    </div>
                    <div className="table-entry-two">
                      <span>{parameterData.datatype}</span>
                    </div>
                    <div className="table-entry-three">
                      <span className="info-text-small">
                        {parameterData.desc}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="documentation-section">
              <span className="bolder-text">Request Body</span>
              <span className="info-text-small">{data.requestBodyDesc}</span>
              <div className="table-block">
                {data.requestProperties.map((propertyData) => (
                  <div className="table-row">
                    <div className="table-entry-one">
                      <span className="code-text">
                        {console.log(propertyData.propertyName)}
                        {propertyData.propertyName}
                      </span>
                    </div>
                    <div className="table-entry-two">
                      <span>{propertyData.datatype}</span>
                    </div>
                    <div className="table-entry-three">
                      <span className="info-text-small">
                        {propertyData.desc}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <span className=" bold-text">Response</span>
            <span className="info-text-small">{data.responseDesc}</span>
            <div className="table-block">
              {data.responsePreoperties.map((propertyData) => (
                <div className="table-row">
                  <div className="table-entry-one">
                    <span className="code-text">
                      {propertyData.propertyName}
                    </span>
                  </div>
                  <div className="table-entry-two">
                    <span>{propertyData.datatype}</span>
                  </div>
                  <div className="table-entry-three">
                    <span className="info-text-small">{propertyData.desc}</span>
                  </div>
                </div>
              ))}
            </div>
            <span className=" bold-text">Helper</span>
            <span className="info-text-small">{data.helperDesc}</span>
            <div className="code-block">
              <span className="code-text">{data.helperCode}</span>
            </div>

            <span className=" bold-text">Sample</span>
            <span className="info-text-small">{data.examleDesc}</span>
            <div className="code-block">
              <span className="code-text">{data.exampleCode}</span>
            </div>
            <hr
              style={{
                background: "#575757",
                color: "#575757",
                borderColor: "#575757",
                height: "3px",
                width: "100%",
              }}
            />
          </>
        ))}
      </div>
    </div>
  );
};

export default ApiReference;

{
  /* <span className="tab-title-text ">File Name</span>
        <div className="settings-input-div">
          <input
            className="normal-input"
            placeholder=""
            // value={}
            onChange={(event) => {}}
          />
        </div>
        <span className="tab-title-text ">Content Type</span>
        <div className="settings-input-div">
          <input
            className="normal-input"
            placeholder=""
            // value={}
            onChange={(event) => {}}
          />
        </div>
        <span className="tab-title-text ">Presigned URL</span>
        <div className="settings-input-div">
          <input
            className="normal-input"
            placeholder=""
            // value={}
            onChange={(event) => {}}
          />
        </div>
        <div className="simple-row">
          <div className=" simple-row secondary-button-blue">
            Regenerate
            <AiOutlineReload size={22} />
            <IoReloadCircle size={26} />
          </div>
          <div className=" simple-row secondary-button-blue">
            Upload
            <AiOutlineCloudUpload size={24} />
            <BsCloudUploadFill size={24} />
          </div>
        </div> */
}
