import React from "react";
import "./ClientInfo.css";
import Topbar from "../../components/Topbar/Topbar";
import ClientCredentials from "../../components/ClientCredentials/ClientCredentials";
import { useSearchParams } from "react-router-dom";
import ApiReference from "../../components/ApiReference/ApiReference";
// import ManualUpload from "../../components/manualUpload/ManualUpload";
import ManualUpload from "../../components/manualUpload_v2/ManualUpload";
import VideoLogs from "../../components/videoLogs/VideoLogs";
import ReportSettings from "../../components/ReportSettings/ReportSettings";
import WebhookSettings from "../../components/WebhookSettings/WebhookSettings";

const ClientInfo = ({ logOut }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab");

  return (
    <div className="clientInfo-root">
      <Topbar logOut={logOut} />
      <div className="clientInfo-wrapper">
        <div className="clientInfo-content-div">
          <div className="clientInfo-options-container">
            <div
              className={
                currentTab === "clientCredentials"
                  ? "clientInfo-option-div-selected"
                  : "clientInfo-option-div"
              }
              onClick={() => {
                searchParams.set("tab", "clientCredentials");
                setSearchParams(searchParams);
              }}
            >
              <span>Client Credentials</span>
            </div>
            <div
              className={
                currentTab === "apiReference"
                  ? "clientInfo-option-div-selected"
                  : "clientInfo-option-div"
              }
              onClick={() => {
                searchParams.set("tab", "apiReference");
                setSearchParams(searchParams);
              }}
            >
              <span className="tab-title-text">API Reference</span>
            </div>
            <div
              className={
                currentTab === "manual_upload"
                  ? "clientInfo-option-div-selected"
                  : "clientInfo-option-div"
              }
              onClick={() => {
                searchParams.set("tab", "manual_upload");
                setSearchParams(searchParams);
              }}
            >
              <span className="tab-title-text">Manual upload</span>
            </div>
            <div
              className={
                currentTab === "video_logs"
                  ? "clientInfo-option-div-selected"
                  : "clientInfo-option-div"
              }
              onClick={() => {
                searchParams.set("tab", "video_logs");
                setSearchParams(searchParams);
              }}
            >
              <span className="tab-title-text">Video logs</span>
            </div>
            <div
              className={
                currentTab === "reportSettings"
                  ? "clientInfo-option-div-selected"
                  : "clientInfo-option-div"
              }
              onClick={() => {
                searchParams.set("tab", "reportSettings");
                setSearchParams(searchParams);
              }}
            >
              <span className="tab-title-text">Report settings</span>
            </div>
            <div
              className={
                currentTab === "webhookSettings"
                  ? "clientInfo-option-div-selected"
                  : "clientInfo-option-div"
              }
              onClick={() => {
                searchParams.set("tab", "webhookSettings");
                setSearchParams(searchParams);
              }}
            >
              <span className="tab-title-text">Webhook settings</span>
            </div>
          </div>
          <div className="clientInfo-option-content">
            {currentTab === "clientCredentials" && <ClientCredentials />}
            {currentTab === "apiReference" && <ApiReference />}
            {currentTab === "manual_upload" && <ManualUpload />}
            {currentTab === "video_logs" && <VideoLogs />}
            {currentTab === "reportSettings" && <ReportSettings />}
            {currentTab === "webhookSettings" && <WebhookSettings />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientInfo;
