import React from "react";
import "./AccountSetup.css";
import ASSETS from "../../assets/Assets";
import { useState } from "react";
import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "../../utils/aws-exports";

const AccountSetup = () => {
  const [orgName, setOrgName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [email, setEmail] = useState("");

  const formFields = {
    signIn: {
      username: {
        label: "",
        placeholder: "Email",
      },
      password: {
        label: "",
        placeholder: "Password",
      },
    },
    signUp: {
      email: {
        order: 1,
        label: "",
      },
      password: {
        order: 2,
        label: "",
      },
      confirm_password: {
        order: 3,
        label: "",
        required: true,
      },
    },
  };

  return (
    <div className="login-screens-wrapper">
      <div className="account-setup-root">
        <div className="account-setup-flexbox">
          <img className="account-setup-image" src={ASSETS.account_setup_1} />
          <div className="account-setup-formbox">
            <div className="form-heading">
              <img src={ASSETS.IBYLogoText} />
              <span className="form-heading-text">|</span>
              <span className="form-heading-text">Dev Console</span>
            </div>
            <span className="form-heading-text-small"> Account Setup</span>

            <Authenticator
              className="auth-form"
              signUpAttributes={["email"]}
              loginMechanisms={["email"]}
              formFields={formFields}
              // initialState={currentSetting === "true" ? "signUp" : "signIn"}
              //services={services}
            />
            {/* <div className="settings-input-div">
              <input
                className="normal-input"
                placeholder="Enter Organisation Name"
                value={orgName}
                onChange={(event) => {
                  setOrgName(event.target.value);
                }}
              />
            </div>
            <div className="settings-input-div">
              <input
                type="text"
                className="normal-input"
                placeholder="Enter First Name"
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
            </div>
            <div className="settings-input-div">
              <input
                type="text"
                className="normal-input"
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
            </div>

            <div className="settings-input-div">
              <input
                type="email"
                className="normal-input"
                value={email}
                placeholder="Enter email"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </div>
            <div className="settings-input-div">
              <input
                type="password"
                className="normal-input"
                value={password}
                placeholder="Password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </div>
            <div className="settings-input-div">
              <input
                type="password"
                className="normal-input"
                value={password2}
                onChange={(event) => {
                  setPassword2(event.target.value);
                }}
                placeholder="Re-enter Password"
              />
            </div>
            <div /> */}

            {/* <div />
            <div />
            {password !== password2 ? (
              <div className="main-button-red">
                <span>Password doesn't match</span>
              </div>
            ) : (
              <div className="main-button-blue">
                <span>Create Account</span>
              </div>
            )}

            <div />

            <div className="footer-text-box">
              <span className="info-text">Aleardy a member? </span>
              <span className="link-text">Sign-in</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSetup;
