import React, { useState, useEffect } from "react";
import "./CostAndUsage.css";
import Topbar from "../../components/Topbar/Topbar";
import { getPricingUsage } from "../../api/organisation";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { getOrgDetails } from "../../api/organisation";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CostAndUsage = ({ logOut }) => {
  const [tableData, setTableData] = useState([]);

  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );

  const fetchOrgDetails = async () => {
    let response = await getOrgDetails(user.username);
    console.log("org data fetched - ", response);
    if (response.status === 200 && response.data.length > 0) {
      const organisation_id = response.data[0].organisation_id;

      const result = await getPricingUsage(organisation_id);

      console.log(result);

      if (Array.isArray(result.data)) {
        setTableData(result.data);
      }
    } else {
    }
  };

  useEffect(() => {
    fetchOrgDetails();
  }, []);

  const column_names = [
    "video_type",
    "count(video_metadata.video_id)",
    "price_usd_per_video",
    "total",
  ];

  const column_names_to_display = [
    "Sr no.",
    "Video type",
    "Number of videos",
    "Price per video (usd)",
    "Total (usd)",
  ];

  return (
    <div className="clientInfo-root">
      <Topbar logOut={logOut} />
      <div className="clientInfo-wrapper">
        <div className="cost-content-div">
          <h2>Cost and usage</h2>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {column_names_to_display.map((column_name) => {
                    return (
                      <StyledTableCell align="center">
                        {column_name}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, idx) => (
                  <StyledTableRow key={row.video_id}>
                    <StyledTableCell align="center">{idx + 1}</StyledTableCell>
                    {column_names.map((column_name) => {
                      return (
                        <StyledTableCell align="center">
                          {row[column_name]}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default CostAndUsage;
