import React, { useState, useEffect, } from "react";
import "./VideoLogs.css";
import axios from "axios";
import { generate_jwt_token } from "../../api/jwt";
import ASSETS from "../../assets/Assets";

export default function ReportDownload(props) {

  const { row, access_key, secret_access_key } = props;
  const [shouldDisplayReportIcon, setShouldDisplayReportIcon] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (
      row.video_analysis_status === "processed" &&
      row.audio_analysis_status === "processed" &&
      row.transcript_analysis_status === "processed" &&
      row.final_analysis_status === "processed" &&
      row.video_segments_status === "processed" &&
      row.overall_analysis_status === "processed"
    ) {
      setShouldDisplayReportIcon(true);
    } else {
      setShouldDisplayReportIcon(false);
    }
  }, [row]);

  const fetchReportUrl = async () => {
    setDownloading(true);
    const config = {
      headers: {
        Authorization: generate_jwt_token(access_key, secret_access_key),
      },
      params: {
        video_id: row.video_id,
      },
    };

    try {
      const response = await axios.get(
        "https://api.imbesideyou.world/report",
        config
      );

      const downloadUrl = response.data.download_url;
      
      return downloadUrl

    } catch (error) {
      console.error('Error fetching report URL:', error);
      setDownloading(false);
    }
  };

  const handleReportDownload = async () => {

    const reportUrl = await fetchReportUrl();

    if (reportUrl) {
      try {
        const response = await fetch(reportUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download =  `${row.video_id}_report.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
      finally {
        setDownloading(false);
      }
    } else {
      // Fetch the report URL if it's not available yet
    }

  };

  return shouldDisplayReportIcon ? (
    <div>
      {downloading ? (
        <span>Downloading... Please wait.</span>
      ) : (
        <img
          src={ASSETS.report}
          style={{ maxWidth: '18px', maxHeight: '18px', cursor: "pointer" }}
          onClick={handleReportDownload}
        />
      )}
    </div>
  ) : null;
}
