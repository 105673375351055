// DO NOT EDIT this file , unless you know what you are doing
const aws = {
  Auth: {
    mandatorySignIn: true,
    /*PRODUCTION*/
    region: "ap-northeast-1",
    userPoolId: "ap-south-1_mc01XyBse",
    userPoolWebClientId: "345lbd5tnhajpr3gk30e280m1", //App Clied Id
    //identityPoolId: "Not made, we have make it",
  },
};

export default aws;
