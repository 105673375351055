import React, { useEffect, useState } from "react";
import "./JobDetail.css";
import Topbar from "../../components/Topbar/Topbar";
import { useAuthenticator } from "@aws-amplify/ui-react";
import config from "../../utils/config";
import axios from "axios";

export default function JobDetail({ logOut }) {
  const { user } = useAuthenticator(
    (context) => [context.user]
  );

  const [videoId, setVideoId] = useState(null);
  const [jobStatus, setJobStatus] = useState("Loading...");
  const [jobStatusColor, setJobStatusColor] = useState("black");
  const [statusReasonsAndSolutions, setStatusReasonsAndSolutions] = useState([]);


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const videoIdParam = queryParams.get("videoId");
    if (videoIdParam) {
      setVideoId(videoIdParam);
    }
  }, []);


  const fetchJobErrorList = async (videoId) => {
    try {

      const baseURL = config.API_URL;
      const path = "/job/error";
      const parameters = "?videoId=" + videoId;
      const response = await axios.get(`${baseURL + path + parameters}`);

      const jobErrorList = response.data;

      // const jobErrorList = [{ cause: "cause1", solution: "solution1" }, { cause: "cause2", solution: "solution2" }]
      // const jobErrorList = []

      console.log("jobErrorList.length", jobErrorList.length)

      setStatusReasonsAndSolutions(jobErrorList);
      setJobStatus(jobErrorList.length === 0 ? "SUCCEEDED" : "FAILED");
      setJobStatusColor(jobErrorList.length === 0 ? "green" : "red");

    } catch (error) {
      console.error("API呼び出し中にエラーが発生しました: ", error);
    }
  };

  useEffect(() => {
    if (videoId) {
      fetchJobErrorList(videoId);
    }
  }, [videoId]);


  console.log("jobStatus", jobStatus)

  return (
    <div className="job-detail-root">
      <Topbar logOut={logOut} />
      <div className="job-detail-wrapper">
        <div className="job-detail-content">
          <h2>Job details</h2>
          <div className="job-detail-items">
            <div className="job-detail-video-video-id">
              <div className="job-detail-item-title">
                video id
              </div>
              <div className="job-detail-item-content">
                {videoId}
              </div>
            </div>
            <div className="job-detail-video-job-status">
              <div className="job-detail-item-title">
                job status
              </div>
              <div className="job-detail-item-content">
                {jobStatus === "SUCCEEDED" ? (
                  <span style={{ color: '#4caf50' }}>No error logs</span>
                ) : (
                  <span style={{ color: '#f44336' }}>Error logs exist</span>
                )}
              </div>
            </div>
            <div className="job-detail-video-job-status-reason">
              <div className="job-detail-item-title">
                status reason
              </div>
              <div className="job-detail-item-content">
                {
                  statusReasonsAndSolutions.length === 0 ? (
                    <span>-</span>
                  ) : (
                    <ul>
                      {statusReasonsAndSolutions.map((row, index) => (
                        <li key={index}>{row.cause}</li>
                      ))}
                    </ul>
                  )
                }
              </div>
            </div>
            <div className="job-detail-video-job-solution">
              <div className="job-detail-item-title">
                solution
              </div>
              <div className="job-detail-item-content">
              {
                  statusReasonsAndSolutions.length === 0 ? (
                    <span>-</span>
                  ) : (
                    <ul>
                      {statusReasonsAndSolutions.map((row, index) => (
                        <li key={index}>{row.solution}</li>
                      ))}
                    </ul>
                  )
                }
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>


  );
};
