import account_setup_1 from "./21474_rectangle.png";
import account_setup_2 from "./21474_rectangle (1).png";
import IBYLogoText from "./gray_yoko.png";
import informationIcon from "./info.svg";
import report from "./report.png";

const ASSETS = {
  account_setup_1,
  account_setup_2,
  IBYLogoText,
  informationIcon,
  report
};

export default ASSETS;
