import React, { useEffect } from "react";
import "./ClientCredentials.css";
import { useState } from "react";
import { FaEye } from "react-icons/fa";
import { getOrgDetails } from "../../api/organisation";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

const ClientCredentials = () => {
  const [clientID, setClientID] = useState();
  const [clientSecret, setClientSecret] = useState();
  const [secretType, setSecretType] = useState("password");

  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );

  const fetchOrgDetails = async () => {
    let response = await getOrgDetails(user.username);
    console.log("org data fetched - ", response);
    if (response.status === 200 && response.data.length > 0) {
      setClientID(response.data[0].api_key);
      setClientSecret(response.data[0].api_secret);
    } else {
    }
  };

  useEffect(() => {
    fetchOrgDetails();
  }, []);

  return (
    <div className="clientCredentials-root">
      <div className="clientCredentials-wrapper">
        <span className="heading-text">Issue API Keys</span>
        <span>
          Use these credentials to access IBY APIs. Please securely store the
          credentials. Do not store them in public repositories.
        </span>
        <span className="tab-title-text ">API ID</span>
        <div className="settings-input-div">
          <input
            className="normal-input"
            placeholder="Client ID here"
            value={clientID}
            onChange={(event) => {}}
          />
        </div>
        <span className="tab-title-text ">API Secret</span>
        <div className="settings-input-div">
          <input
            type={secretType}
            className="normal-input"
            placeholder="Client Secret here"
            value={clientSecret}
            onChange={(event) => {}}
          />
          <FaEye
            size={22}
            onClick={() => {
              setSecretType((prev) => {
                if (prev === "text") {
                  return "password";
                }
                return "text";
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientCredentials;
