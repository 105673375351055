import React, { useEffect, useState } from "react";
import AccountSetup from "../screens/AccountSetup/AccountSetup";

import {
  Authenticator,
  useAuthenticator,
  Heading,
  useTheme,
  TextField,
  Button as AwsButton,
  Alert,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

// import { verifyOrganisationAPI } from "../../api/verifyOrganisationCode";

import "./AuthApp.css";

function AuthApp({ App }) {
  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );

  const [status, setStatus] = useState("loading");

  useEffect(() => {
    if (typeof user === "undefined") {
      setStatus("unauthenticated");
    } else if (route === "confirmSignUp") {
      setStatus("unauthenticated");
    } else {
      setStatus("authenticated");
    }
    console.log(user);
  }, [user, route]);

  const logOut = () => {
    setStatus("unauthenticated");
    signOut();
  };

  return (
    <div>
      {status === "authenticated" ? (
        <App logOut={logOut} />
      ) : status === "unauthenticated" ? (
        <AccountSetup />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default AuthApp;
