import React, { useEffect, useState } from "react";
import "./ReportSettings.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import config from "../../utils/config";
import axios from "axios";

export default function ReportSettings() {
  const { user } = useAuthenticator(
    (context) => [context.user]
  );

  const [formData, setFormData] = useState({});
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [buttonText, setButtonText] = useState("Update");

  const [showDeleteOptions, setShowDeleteOptions] = useState(false);

  const [selectedStatusCode, setSelectedStatusCode] = useState(200);

  const getReportSettings = async (organisation_id) => {
    const baseURL = config.API_URL;
    const path = "/report/setting";
    const parameters = "?organisation_id=" + organisation_id;
    const response = await axios.get(`${baseURL + path + parameters}`);

    return response;
  };

  const updateReportSettings = async () => {
    const baseURL = config.API_URL;
    const path = "/report/setting";
    const response = await axios.post(`${baseURL + path}`, { ...formData, organisation_id: user.username });

    return response;
  };

  const getLogo = async (organisation_id) => {
    const baseURL = config.API_URL;
    const path = "/report/setting/logo";
    const parameters = "?organisation_id=" + organisation_id;
    const response = await axios.get(`${baseURL + path + parameters}`);

    return response;
  };

  const deleteLogo = async (organisation_id) => {
    const baseURL = config.API_URL;
    const path = "/report/setting/logo";
    const parameters = "?organisation_id=" + user.username;
    const response = await axios.delete(`${baseURL + path + parameters}`);

    return response;
  };

  const getLogoUploadUrl = async (fileName) => {
    const baseURL = config.API_URL;
    const path = "/report/setting/logo";
    const response = await axios.post(`${baseURL + path}`, { organisation_id: user.username, file_name: fileName });

    return response;
  };

  const fetchOrgDetails = async () => {

    try {
      let response = await getReportSettings(user.username);
      console.log("response", response)
      setFormData((prevState) => ({
        ...prevState,
        company_name: response.data[0]?.company_name,
        phone: response.data[0]?.phone,
        email: response.data[0]?.email
      }));

      let response2 = await getLogo(user.username);
      console.log("response2", response2)
      setLogoUrl(response2.data.pre_signed_url);
    } catch (err) {
      console.log(err)
    } finally {

    }
  };

  useEffect(() => {
    fetchOrgDetails();
  }, [user]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // 選択した画像をstateに保存
      setSelectedImage(file);
      setIsFormDirty(true)
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setIsFormDirty(true)
  };

  const handleDeleteLogo = () => {
    setShowDeleteOptions(true);
  };

  const handleCancel = () => {
    setShowDeleteOptions(false);
  };

  const handleConfirmDelete = async (e) => {
    e.preventDefault();
    setShowDeleteOptions(false);
    try {
      const response = await deleteLogo(user.username);
    } catch (err) {
      setError(err);
      setButtonText("Error");
    } finally {
      setLoading(false);
    }
    setLogoUrl("")

  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await updateReportSettings(user.username);
      console.log("selectedImage", selectedImage)
      if (selectedImage) {
        const fileName = selectedImage.name;
        console.log("fileName", fileName)
        const response2 = await getLogoUploadUrl(fileName);
        const presignedS3Url = response2.data.pre_signed_url;
        console.log("presignedS3Url", presignedS3Url)
        const response3 = await axios.put(presignedS3Url, selectedImage, { headers: { 'Content-Type': selectedImage.type } });
      }

      setSuccess(true);
      setButtonText("Saved");
    } catch (err) {
      setError(err);
      setButtonText("Error");
    } finally {
      setLoading(false);
    }

  }

  console.log("formData", formData)

  const data = {
    httpRequestCode: "GET /report",
    parameters: [
      {
        parameterName: "video_id",
        datatype: "string",
        desc: `Please specify the "video_id" for which you want to retrieve the report.`,
      },
      {
        parameterName: "interviewee_name",
        datatype: "string",
        desc: `You can specify the interviewee name to be included in the report. If there is no specification, the interviewee name displayed in the report will be "-" by default.`,
      },
    ],
    requestHeaderProperties: [
      {
        propertyName: "Authorization",
        datatype: "string",
        desc: `It's the JWT token generated using your API key and API Secret.`,
      }
    ],
    requestBodyDesc: "You will receive a URL where you can download the report.",
    responseBodies: {
      200: [
        { propertyName: 'download_url', datatype: 'String', desc: `a URL where you can download the report.` },
      ],
      403: [
        { propertyName: 'message', datatype: 'String', desc: 'Forbidden' },
      ],
      404: [
        { propertyName: 'message', datatype: 'String', desc: 'Not found the video_id' },
      ],
      503: [
        { propertyName: 'message', datatype: 'String', desc: 'Analysis in Progress' },
      ],
    }
  }

  return (
    <div className="report-setting-root">
      <div className="report-setting-wrapper">
        <span className="heading-text code-text">Report settings</span>
        <div className="report-setting-grid">
          <div className="report-setting-setting-grid">
            <span>The information you enter below will be included in the report.</span>
            <form className="report-setting-form">
              <div className="report-setting-form-input-company-name">
                <div className="report-setting-form-title-text">Company Name</div>
                <div>
                  <input
                    className="normal-input"
                    name="company_name"
                    type="text"
                    value={formData?.company_name}
                    onChange={handleInputChange} />
                </div>
              </div>

              <div className="report-setting-form-input-phone">
                <div className="report-setting-form-title-text">Phone number</div>
                <div>
                  <input
                    className="normal-input"
                    name="phone"
                    type="tel"
                    value={formData?.phone}
                    onChange={handleInputChange} />
                </div>
              </div>

              <div className="report-setting-form-input-emal">
                <div className="report-setting-form-title-text">Email</div>
                <div>
                  <input
                    className="normal-input"
                    name="email"
                    type="email"
                    value={formData?.email}
                    onChange={handleInputChange} />
                </div>
              </div>

              <div className="report-setting-form-input-company-logo">
                <div className="report-setting-form-title-text">Company logo</div>
                <div>
                  {selectedImage && (
                    <img src={URL.createObjectURL(selectedImage)} style={{ maxWidth: '100px', maxHeight: '100px', marginBottom: '12px' }} />
                  )}
                  {!selectedImage && logoUrl && (
                    <img src={logoUrl} style={{ maxWidth: '100px', maxHeight: '100px', marginBottom: '12px' }} />
                  )}
                  {!selectedImage && !logoUrl && (
                    <div className="no-logo-box">No logo data</div>
                  )}
                  <div>
                    <label className="report-setting-form-input-company-logo-upload-button">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />Select logo
                    </label>
                    <div className="report-setting-form-input-company-logo-delete">
                      <span onClick={handleDeleteLogo}>Delete logo</span>
                    </div>
                    {showDeleteOptions && (
                      <div className="delete-options">
                        <button onClick={handleConfirmDelete}>Delete</button>
                        <button className="cancel-button" onClick={handleCancel}>Cancel</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="report-setting-form-submit">
                <button
                  className={`report-setting-update-button ${loading ? "loading" : success ? "success" : error ? "error" : ""
                    }`}
                  type="submit"
                  onClick={handleFormSubmit}
                  disabled={!isFormDirty}
                >{loading ? "Sending..." : buttonText}</button>
              </div>
            </form>
          </div>

          <div className="report-setting-spec-grid">
            <div className="report-setting-spec-wrapper">
              <span className=" bold-text">API to download Report</span>
              <div className="documentation-section">
                <div className="code-block">
                  <span className="code-text">{data.httpRequestCode}</span>
                </div>
              </div>
              <div className="documentation-section">
                <span className="bolder-text">Query Parameters</span>
                <div className="table-block">
                  {data.parameters.map((parameterData) => (
                    <div className="table-row">
                      <div className="table-entry-one">
                        <span className="code-text">
                          {parameterData.parameterName}
                        </span>
                      </div>
                      <div className="table-entry-two">
                        <span>{parameterData.datatype}</span>
                      </div>
                      <div className="table-entry-three">
                        <span className="info-text-small">
                          {parameterData.desc}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="documentation-section">
                <span className="bolder-text">Request Headers</span>
                <span className="info-text-small">{data.requestHeaderDesc}</span>
                <div className="table-block">
                  {data.requestHeaderProperties.map((propertyData) => (
                    <div className="table-row">
                      <div className="table-entry-one">
                        <span className="code-text">
                          {console.log(propertyData.propertyName)}
                          {propertyData.propertyName}
                        </span>
                      </div>
                      <div className="table-entry-two">
                        <span>{propertyData.datatype}</span>
                      </div>
                      <div className="table-entry-three">
                        <span className="info-text-small">
                          {propertyData.desc}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <span className="bolder-text">Response Body</span>
              <div className="button-container">
                <button
                  onClick={() => setSelectedStatusCode(200)}
                  className={selectedStatusCode === 200 ? "selected" : ""}
                >
                  200
                </button>
                <button
                  onClick={() => setSelectedStatusCode(403)}
                  className={selectedStatusCode === 403 ? "selected" : ""}
                >
                  403
                </button>
                <button
                  onClick={() => setSelectedStatusCode(404)}
                  className={selectedStatusCode === 404 ? "selected" : ""}
                >
                  404
                </button>
                <button
                  onClick={() => setSelectedStatusCode(503)}
                  className={selectedStatusCode === 503 ? "selected" : ""}
                >
                  503
                </button>
              </div>
              <div className="documentation-section">
                <span className="bolder-text">Response Body</span>
                <div className="table-block">
                  {data.responseBodies[selectedStatusCode].map((propertyData) => (
                    <div className="table-row">
                      <div className="table-entry-one">
                        <span className="code-text">
                          {console.log(propertyData.propertyName)}
                          {propertyData.propertyName}
                        </span>
                      </div>
                      <div className="table-entry-two">
                        <span>{propertyData.datatype}</span>
                      </div>
                      <div className="table-entry-three">
                        <span className="info-text-small">
                          {propertyData.desc}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
};
