import React, { useEffect } from "react";
import "./KYCScreen.css";
import { useState } from "react";
import ASSETS from "../../assets/Assets";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import { async } from "q";
import { createOrg } from "../../api/organisation";

const KYCScreen = ({ logOut }) => {
  const [orgName, setOrgName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [email, setEmail] = useState("");

  const [registered, setRegistered] = useState(false);
  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );
  const handleRegister = async () => {
    console.log("auth user - ", user);

    let response = await createOrg(
      user.username,
      orgName,
      firstName,
      lastName,
      email
    );

    if (response.status === 200 || response.status === 201) {
      setRegistered(true);
      setTimeout(function () {
        // window.location.reload();
        window.location.assign("clientInfo?tab=clientCredentials");
      }, 2000);
    }
  };

  useEffect(() => {
    setEmail(user.attributes.email);
  }, [user]);

  return (
    <div className="login-screens-wrapper">
      <div className="account-setup-root">
        <div className="account-setup-flexbox">
          <img className="account-setup-image" src={ASSETS.account_setup_2} />
          <div className="account-setup-formbox">
            <div className="form-heading">
              <img src={ASSETS.IBYLogoText} />
              <span className="form-heading-text">|</span>
              <span className="form-heading-text">Dev Console</span>
            </div>
            <span className="form-heading-text-small"> Account Setup</span>

            <div className="settings-input-div">
              <input
                className="normal-input"
                placeholder="Enter Organisation Name"
                value={orgName}
                onChange={(event) => {
                  setOrgName(event.target.value);
                }}
              />
            </div>
            <div className="settings-input-div">
              <input
                type="text"
                className="normal-input"
                placeholder="Enter First Name"
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
            </div>
            <div className="settings-input-div">
              <input
                type="text"
                className="normal-input"
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
            </div>
            <div className="settings-input-div">
              <input
                type="email"
                className="normal-input"
                contentEditable={false}
                value={email}
                // value={""}
                placeholder={email}
                onChange={(event) => {
                  // setEmail(event.target.value);
                }}
              />
            </div>
            <div
              className={registered ? "main-button-green" : "main-button-blue"}
              onClick={handleRegister}
            >
              <span>{registered ? "Successful!" : "Register"}</span>
            </div>
            <div className="main-button-red" onClick={logOut}>
              <span>Log Out</span>
            </div>

            {/*<div className="settings-input-div">
              <input
                type="password"
                className="normal-input"
                value={password}
                placeholder="Password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </div>
            <div className="settings-input-div">
              <input
                type="password"
                className="normal-input"
                value={password2}
                onChange={(event) => {
                  setPassword2(event.target.value);
                }}
                placeholder="Re-enter Password"
              />
            </div> */}
            <div />

            {/* <div />
            <div />
            {password !== password2 ? (
              <div className="main-button-red">
                <span>Password doesn't match</span>
              </div>
            ) : (
              <div className="main-button-blue">
                <span>Create Account</span>
              </div>
            )}

            <div />

            <div className="footer-text-box">
              <span className="info-text">Aleardy a member? </span>
              <span className="link-text">Sign-in</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYCScreen;
