import React, { useState, useCallback, useEffect, Fragment } from "react";
import "./VideoLogs.css";
import axios from "axios";
import { generate_jwt_token } from "../../api/jwt";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { readOrg } from "../../api/organisation";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";

import { Dna } from "react-loader-spinner";

import IconButton from "@mui/material/IconButton";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";


import ReactJson from "react-json-view";

import ReportDownload from './ReportDownload';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const column_names = [
  "video_id",
  "video_type",
  "creation_date",
  "video_title",
  "video_analysis_status",
  "audio_analysis_status",
  "transcript_analysis_status",
  "final_analysis_status",
  "video_segments_status",
  "overall_analysis_status",
  "duration_in_sec",
  "file_name",
  "uploaded",
];

const column_names_to_display = [
  "Show analysis",
  "Video id",
  "Video type",
  "Creation date",
  "Video title",
  "Video analysis status",
  "Audio analysis status",
  "Transcript analysis status",
  "Tags assign status",
  "Video segments status",
  "Overall analysis status",
  "Video duration (s)",
  "Filename",
  "Uploaded",
  "Report",
];

function Row(props) {

  const { row, access_key, secret_access_key } = props;
  const [open, setOpen] = React.useState(false);
  const [analysisData, setAnalysisData] = useState({});
  const [loading, setLoading] = useState(false);

  const get_analysis_helper = async () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: generate_jwt_token(access_key, secret_access_key),
      },
    };

    const result = await axios.get(
      "https://api.imbesideyou.world/analysis?video_id=" + row.video_id,
      config
    );

    console.log(result);

    setLoading(false);
    setAnalysisData(result);
  };

  useEffect(() => {
    if (open) {
      get_analysis_helper();
    }
  }, [open]);

  return (
    <Fragment>
      <StyledTableRow key={row.video_id}>
        <StyledTableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>

        {column_names.map((column_name) => {
          return (
            <StyledTableCell align="center">
              {column_name === 'video_id' ? (
                <a href={`/jobDetail?videoId=${row[column_name]}`}>{row[column_name]}</a>
              ) : (
                row[column_name]
              )}
            </StyledTableCell>
          );
        })}
        <StyledTableCell>
          <ReportDownload
            row={row}
            access_key={access_key}
            secret_access_key={secret_access_key}
          />
        </StyledTableCell>
      </StyledTableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {loading ? (
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            ) : null}
            {/* <JSONInput
              id="a_unique_id2"
              locale={locale}
              height="100%"
              placeholder={analysisData}
            /> */}
            <ReactJson src={analysisData} collapsed={true} />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function VideoLogs() {
  const [responsedata, setResponsedata] = useState([]);

  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );

  let cognitoUserId = user.username;
  // let cognitoUserId = "768c4124-da52-444d-b95b-537d21ebf005";

  const [access_key, set_access_key] = useState();
  const [secret_access_key, set_secret_access_key] = useState();

  const getOrgDetailsHelper = async () => {
    const res = await readOrg(cognitoUserId);
    const access_key = res?.data?.[0]?.api_key;
    const secret_access_key = res?.data?.[0]?.api_secret;

    set_access_key(access_key);
    set_secret_access_key(secret_access_key);

    await handleGetVideoLogs(access_key, secret_access_key);
  };

  useEffect(() => {
    getOrgDetailsHelper();
  }, []);

  const handleGetVideoLogs = async (access_key, secret_access_key) => {
    const config = {
      headers: {
        Authorization: generate_jwt_token(access_key, secret_access_key),
      },
    };

    const result = await axios.get(
      "https://api.imbesideyou.world/analysis",
      config
    );

    setResponsedata(result);
  };

  return (
    <div className="video-logs-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {column_names_to_display.map((column_name) => {
                return (
                  <StyledTableCell align="center">
                    {column_name}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {responsedata?.data?.video_list?.map((row) => {
              const uploadedValue = row.uploaded === 1 ? "Success" : "Failed";
              return (
                <Row
                  key={row.name}
                  row={{ ...row, uploaded: uploadedValue }}
                  access_key={access_key}
                  secret_access_key={secret_access_key}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
