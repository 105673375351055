import React, { useEffect, useState } from "react";
import "./LaunchScreen.css";
import ClientInfo from "../ClientInfo/ClientInfo";
import KYCScreen from "../KYCScreen/KYCScreen";
// import ClientInfo from "../ClientInfo/ClientInfo";
import { getOrgDetails } from "../../api/organisation";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { async } from "q";
const LaunchScreen = ({ logOut }) => {
  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );
  const [orgExists, setOrgExists] = useState();

  const fetchOrgDetails = async () => {
    let response = await getOrgDetails(user.username);
    console.log("org data fetched - ", response);
    if (response.status === 200 && response.data.length > 0) {
      setOrgExists(true);
    } else {
      setOrgExists(false);
    }
  };

  useEffect(() => {
    fetchOrgDetails();
  }, []);

  return (
    <div className="launch-screen-root">
      {!orgExists && <KYCScreen logOut={logOut} />}
      {orgExists && <ClientInfo />}
    </div>
  );
};

export default LaunchScreen;
