import "./App.css";
import AccountSetup from "./screens/AccountSetup/AccountSetup";
import IssueApiKey from "./screens/IssueApiKey/IssueApiKey";
import ClientInfo from "./screens/ClientInfo/ClientInfo";
import LaunchScreen from "./screens/LaunchScreen/LaunchScreen";
import { getOrgDetails } from "./api/organisation";

import JobDetail from "./components/JobDetail/JobDetail";

import {
  Routes,
  Route,
  useLocation,
  // Router,
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import Profile from "./screens/Profile/Profile";

import awsExports from "./utils/aws-exports";
import CostAndUsage from "./screens/CostAndUsage/CostAndUsage";
import AuthApp from "./Auth/AuthApp";

Amplify.configure(awsExports);
function InterviewAnalysisApp({ logOut }) {
  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );
  const [orgExists, setOrgExists] = useState();

  const fetchOrgDetails = async () => {
    let response = await getOrgDetails(user.username);
    console.log("org data fetched - ", response);
    if (response.status === 200 && response.data.length > 0) {
      setOrgExists(true);
    } else {
      setOrgExists(false);
    }
  };

  useEffect(() => {
    fetchOrgDetails();
  }, []);

  const routes = useRoutes([
    { path: "/", element: <LaunchScreen logOut={logOut} /> },
    // { path: "/accountSetup", element: <AccountSetup /> },
    { path: "/issueApiKey", element: <IssueApiKey logOut={logOut} /> },
    { path: "/clientInfo", element: <ClientInfo logOut={logOut} /> },
    { path: "/costAndUsage", element: <CostAndUsage logOut={logOut} /> },
    { path: "/profile", element: <Profile logOut={logOut} /> },
    { path: "/jobDetail", element: <JobDetail logOut={logOut} /> },

    // Define more routes here
  ]);

  const routesForLaunch = useRoutes([
    { path: "/*", element: <LaunchScreen logOut={logOut} /> },
  ]);

  return <div className="app">{orgExists ? routes : routesForLaunch}</div>;
}

const AppWrapper = ({ logOut }) => {
  return (
    <Router>
      <InterviewAnalysisApp logOut={logOut} />
    </Router>
  );
};

const App = () => {
  return (
    <Authenticator.Provider>
      <AuthApp App={AppWrapper} />
    </Authenticator.Provider>
  );
};

export default App;
