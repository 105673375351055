const CryptoJS = require("crypto-js");

const base64url = (source) => {
  let encodedSource = CryptoJS.enc.Base64.stringify(source);
  encodedSource = encodedSource.replace(/=+$/, "");
  encodedSource = encodedSource.replace(/\+/g, "-");
  encodedSource = encodedSource.replace(/\//g, "_");
  return encodedSource;
};

const createJWT = (header, data, secret = undefined) => {
  let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  let encodedHeader = base64url(stringifiedHeader);
  let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  let encodedData = base64url(stringifiedData);
  let token = encodedHeader + "." + encodedData;
  if (!secret) return token;
  let signature = CryptoJS.HmacSHA256(token, secret);
  signature = base64url(signature);

  console.log(signature);
  console.log(encodedHeader + "." + encodedData + "." + signature);
  return encodedHeader + "." + encodedData + "." + signature;
};

export const generate_jwt_token = (access_key, secret_access_key) => {
  let header = { alg: "HS256", typ: "JWT" };

  let expiration = new Date().getTime() / 1000 + 1000;

  let data = {
    iss: access_key,
    exp: expiration,
  };

  let token = createJWT(header, data, secret_access_key);

  let output = `Bearer ${token}`;

  console.log(output);

  return output;
};
