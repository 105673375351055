import React from "react";
import "./Topbar.css";
import ASSETS from "../../assets/Assets";
import { Link, useLocation } from "react-router-dom";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

const Topbar = ({ logOut }) => {
  const location = useLocation();
  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );

  return (
    <div className="topbar-root">
      <div className="topbar-icon-div">
        <div className="form-heading">
          <img src={ASSETS.IBYLogoText} />
          <span className="form-heading-text">|</span>
          <span className="form-heading-text">Dev Console</span>
        </div>
      </div>
      <div className="topbar-tabs-div">
        <Link to="/clientInfo" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/clientInfo"
                ? "selected-tab"
                : "unselected-tab"
            }
          >
            <span className="tab-title-text">API</span>
          </div>
        </Link>
        <Link to="/costAndUsage" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/costAndUsage"
                ? "selected-tab"
                : "unselected-tab"
            }
          >
            <span className="tab-title-text">Cost and Usage</span>
          </div>
        </Link>
        <Link to="/profile" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/profile"
                ? "selected-tab"
                : "unselected-tab"
            }
          >
            <span className="tab-title-text">Profile</span>
          </div>
        </Link>
        <div
          className={
            location.pathname === "/logOut" ? "selected-tab" : "unselected-tab"
          }
          onClick={logOut}
        >
          <span className="tab-title-text">Log out</span>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
