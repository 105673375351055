import React, { useEffect, useState } from "react";
import "./WebhookSettings.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import config from "../../utils/config";
import axios from "axios";
import { generate_jwt_token } from "../../api/jwt";
import { readOrg } from "../../api/organisation";
import ASSETS from "../../assets/Assets";

export default function WebhookSettings() {
  const { user } = useAuthenticator(
    (context) => [context.user]
  );

  const [access_key, set_access_key] = useState("");
  const [secret_access_key, set_secret_access_key] = useState("");

  const [formData, setFormData] = useState({});
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [buttonText, setButtonText] = useState("Update");

  const [nwChecking, setNwChecking] = useState(false);
  const [nwSuccess, setNwSuccess] = useState(false);
  const [nwError, setNwError] = useState(null);
  const [nwButtonText, setNwButtonText] = useState("Webhok test");

  const [showDeleteOptions, setShowDeleteOptions] = useState(false);


  const getWebhookSettings = async (organisation_id) => {
    const baseURL = config.API_URL;
    const path = "/webhook/setting";
    const parameters = "?organisation_id=" + organisation_id;
    const response = await axios.get(`${baseURL + path + parameters}`);

    return response;
  };

  const updateWebhookSettings = async () => {
    const baseURL = config.API_URL;
    const path = "/webhook/setting";
    const response = await axios.post(`${baseURL + path}`, { ...formData, organisation_id: user.username });

    return response;
  };

  const deleteWebhookSettings = async (organisation_id) => {
    const baseURL = config.API_URL;
    const path = "/webhook/setting";
    const parameters = "?organisation_id=" + organisation_id;
    const response = await axios.delete(`${baseURL + path + parameters}`);

    return response;
  };

  const getOrgDetailsHelper = async () => {
    const res = await readOrg(user.username);
    set_access_key(res?.data?.[0]?.api_key);
    set_secret_access_key(res?.data?.[0]?.api_secret);
  };

  useEffect(() => {
    getOrgDetailsHelper();
  }, []);

  const networkCheck = async () => {

    const config = {
      headers: {
        Authorization: generate_jwt_token(access_key, secret_access_key),
      },
    };

    const bodyParameters = {
      video_id: "dummy_video_id",
      event: "completed",
      status_code: 0,
      message: "All analysis completed"
    };

    const response = await axios.post(formData.endpoint, bodyParameters, config);
    return response;
  };


  const fetchOrgDetails = async () => {

    try {
      let response = await getWebhookSettings(user.username);
      console.log("response", response)
      setFormData((prevState) => ({ ...prevState, endpoint: response.data[0].endpoint }));
    } catch (err) {

    } finally {

    }
  };

  useEffect(() => {
    fetchOrgDetails();
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setIsFormDirty(true)
  };

  const handleDeleteSetting = () => {
    setShowDeleteOptions(true);
  };

  const handleCancel = () => {
    setShowDeleteOptions(false);
  };

  const handleConfirmDelete = async (e) => {
    e.preventDefault();
    setShowDeleteOptions(false);
    try {
      const response = await deleteWebhookSettings(user.username);
    } catch (err) {
      setError(err);
      setButtonText("Error");
    } finally {
      setLoading(false);
    }
    setFormData((prevState) => ({endpoint: ""}));
    setIsFormDirty(false)

  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await updateWebhookSettings(user.username);

      setSuccess(true);
      setButtonText("Saved");
    } catch (err) {
      setError(err);
      setButtonText("Error");
    } finally {
      setLoading(false);
    }

  }

  const handleNetworkCheck = async (e) => {
    e.preventDefault();
    setNwChecking(true);

    try {
      const response = await networkCheck();
      setNwSuccess(true);
      setNwButtonText("Success");
    } catch (err) {
      setNwError(err);
      setNwButtonText("Error");
    } finally {
      setNwChecking(false);
    }

  }

  console.log("formData", formData)

  const data = {
    httpRequestCode: "POST {Your endpoint}",
    parameters: [],
    requestHeaderProperties: [
      {
        propertyName: "Authorization",
        datatype: "string",
        desc: `It's the JWT token generated using your API key and API Secret.`,
      }
    ],
    requestBodyProperties: [
      {
        propertyName: "video_id",
        datatype: "string",
        desc: `The "video_id" is the target for notifying the completion of the analysis results.`,
      },
      {
        propertyName: "event",
        datatype: "string",
        desc: `It takes one of the values: "completed," "partial_incompleted," or "incompleted." For more details, please refer to the "Events" section below.`,
      },
      {
        propertyName: "status_code",
        datatype: "int",
        desc: `Storing status code information related to the error cause. For more details, please refer to the "Events" section below.`,
      },
      {
        propertyName: "message",
        datatype: "string",
        desc: `Storing the message that serves as the reason or explanation when it's partial_incompleted/incompleted.`,
      }
    ],
    eventsDesc: `Each row contains the "event", "status_code", "message", and also additional description for Request Body, listed from left to right.` ,
    eventsProperties: [
      {
        eventName: "completed",
        statusCode: "0",
        message: "All analysis completed",
        desc: `It is sent when all the analysis has been successfully completed.`,
      },
      {
        eventName: "partial_incompleted",
        statusCode: "1001",
        message: "No audio analysis",
        desc: `It is sent when there is no audio in the video, and therefore, analysis related to audio or transcript could not be performed.`,
      },
      {
        eventName: "partial_incompleted",
        statusCode: "1002",
        message: "No facial analysis",
        desc: `It is sent when there are no faces visible in the video, resulting in the inability to perform facial expression analysis.`,
      },
      {
        eventName: "incompleted",
        statusCode: "1003",
        message: "No analysis",
        desc: `It is sent when there is neither audio nor faces visible in the video, resulting in the inability to perform any analysis.`,
      },
      {
        eventName: "Invalid file extension:{file_name}",
        statusCode: "1004",
        message: "The supported file extensions are as follows: Video files: mp4, webm, mov Document files: pdf, csv, xls, xlsx, docx, txt Please replace your file with one of the supported formats and try uploading again.",
        desc: `It is sent when a file with an unsupported file extension is uploaded.`,
      },
      {
        eventName: "Invalid file extension:{file_name}",
        statusCode: "1005",
        message: "Please check if the file can be opended on your side. If there are any issues with the file, please replace the file and upload it again. If there are no issues with the file, please contact our Customer Support team.",
        desc: `It is sent when the uploaded file cannot be loaded by the system`,
      }
    ]
  }

  return (
    <div className="webhook-setting-root">
      <div className="webhook-setting-wrapper">
        <span className="heading-text code-text">webhook settings</span>
        <div className="webhook-setting-grid">
          <div className="webhook-setting-form-grid">
            <span>When the analysis is completed, a webhook will be sent to the endpoint specified below.</span>


            <form className="webhook-setting-form">
              <div className="webhook-setting-form-input-endpoint">
                <span className="webhook-setting-form-title-text">Endpoint</span>
                <div>
                  <input
                    className="webhook-setting-form-input"
                    name="endpoint"
                    placeholder="ex: https://www.imbesideyou.com"
                    type="text"
                    value={formData?.endpoint}
                    onChange={handleInputChange} />
                  <button
                    className={`webhook-setting-nw-check-button ${nwChecking ? "checking" : nwSuccess ? "success" : nwError ? "error" : ""
                      }`}
                    onClick={handleNetworkCheck}
                  >
                    {nwChecking ? "Checking..." : nwButtonText}
                  </button>
                  <div class="webhook-setting-info-icon">
                    <img src={ASSETS.informationIcon} />
                    <div class="webhook-setting-info-tooltip">In this test, a dummy video_id is used to send a request with a format same to the actual webhook to the specified endpoint.</div>
                  </div>

                </div>
              </div>
              <div className="webhook-setting-form-submit">
                <button
                  className={`webhook-setting-update-button ${loading ? "loading" : success ? "success" : error ? "error" : ""
                    }`}
                  type="submit"
                  onClick={handleFormSubmit}
                  disabled={!nwSuccess}
                >
                  {loading ? "Sending..." : buttonText}
                </button>
                <div className="report-setting-form-input-company-logo-delete">
                  <span onClick={handleDeleteSetting}>Delete settings</span>
                </div>
                {showDeleteOptions && (
                  <div className="delete-options">
                    <button onClick={handleConfirmDelete}>Delete</button>
                    <button className="cancel-button" onClick={handleCancel}>Cancel</button>
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="webhook-setting-webhook-spec-grid">
            <div className="webhook-setting-webhook-wrapper">
              <span className=" bold-text">Webhook</span>
              <div className="documentation-section">
                <div className="code-block">
                  <span className="code-text">{data.httpRequestCode}</span>
                </div>
              </div>
              <div className="documentation-section">
                <span className="bolder-text">Parameters</span>
                <div className="table-block">
                  {data.parameters.map((parameterData) => (
                    <div className="table-row">
                      <div className="table-entry-one">
                        <span className="code-text">
                          {parameterData.parameterName}
                        </span>
                      </div>
                      <div className="table-entry-two">
                        <span>{parameterData.datatype}</span>
                      </div>
                      <div className="table-entry-three">
                        <span className="info-text-small">
                          {parameterData.desc}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="documentation-section">
                <span className="bolder-text">Request Headers</span>
                <span className="info-text-small">{data.requestHeaderDesc}</span>
                <div className="table-block">
                  {data.requestHeaderProperties.map((propertyData) => (
                    <div className="table-row">
                      <div className="table-entry-one">
                        <span className="code-text">
                          {console.log(propertyData.propertyName)}
                          {propertyData.propertyName}
                        </span>
                      </div>
                      <div className="table-entry-two">
                        <span>{propertyData.datatype}</span>
                      </div>
                      <div className="table-entry-three">
                        <span className="info-text-small">
                          {propertyData.desc}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="documentation-section">
                <span className="bolder-text">Request Body</span>
                <span className="info-text-small">{data.requestBodyDesc}</span>
                <div className="table-block">
                  {data.requestBodyProperties.map((propertyData) => (
                    <div className="table-row">
                      <div className="table-entry-one">
                        <span className="code-text">
                          {console.log(propertyData.propertyName)}
                          {propertyData.propertyName}
                        </span>
                      </div>
                      <div className="table-entry-two">
                        <span>{propertyData.datatype}</span>
                      </div>
                      <div className="table-entry-three">
                        <span className="info-text-small">
                          {propertyData.desc}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="documentation-section">
                <span className="bolder-text">Events</span>
                <span className="info-text-small">{data.eventsDesc}</span>
                <div className="table-block">
                  {data.eventsProperties.map((propertyData) => (
                    <div className="table-row">
                      <div className="table-entry-one">
                        <span className="code-text">
                          {propertyData.eventName}
                        </span>
                      </div>
                      <div className="table-entry-two">
                        <span>{propertyData.statusCode}</span>
                      </div>
                      <div className="table-entry-three">
                        <span className="info-text-small">
                          {propertyData.message}
                        </span>
                      </div>
                      <div className="table-entry-four">
                        <span className="info-text-small">
                          {propertyData.desc}
                        </span>
                      </div>
                    </div>

                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >


  );
};
