import React, { useEffect, useState } from "react";
import Topbar from "../../components/Topbar/Topbar";
import "./Profile.css";
import { getOrgDetails } from "../../api/organisation";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { SetMealRounded } from "@mui/icons-material";
// import { useState, useEffect } from "react";

const Profile = ({ logOut }) => {
  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );

  const [orgName, setOrgName] = useState("loading...");
  const [firstName, setFirstName] = useState("loading...");
  const [lastName, setLastName] = useState("loading...");
  const [email, setEmail] = useState("loading...");

  const fetchOrgDetails = async () => {
    let response = await getOrgDetails(user.username);
    console.log("org data in profile page fetched - ", response);
    if (response.status === 200 && response.data.length > 0) {
      setEmail(response.data[0].email_id);
      setOrgName(response.data[0].organisation_name);
      setLastName(response.data[0].last_name);
      setFirstName(response.data[0].first_name);
    } else {
      setEmail("Error");
      setOrgName("Error");
      setLastName("Error");
      setFirstName("Error");
    }
  };

  useEffect(() => {
    fetchOrgDetails();
  }, [user]);

  return (
    <div className="clientInfo-root">
      <Topbar logOut={logOut} />
      <div className="clientInfo-wrapper">
        <div className="profile-content-div">
          <h2>Profile</h2>
          <span className="tab-title-text ">Organisation Name</span>
          <div className="settings-input-div">
            <input
              className="normal-input"
              placeholder="Client ID here"
              value={orgName}
              onChange={(event) => {}}
            />
          </div>
          <span className="tab-title-text ">First Name</span>
          <div className="settings-input-div">
            <input
              className="normal-input"
              placeholder="Client ID here"
              value={firstName}
              onChange={(event) => {}}
            />
          </div>
          <span className="tab-title-text ">Last Name</span>
          <div className="settings-input-div">
            <input
              className="normal-input"
              placeholder="Client ID here"
              value={lastName}
              onChange={(event) => {}}
            />
          </div>
          <span className="tab-title-text ">Email</span>
          <div className="settings-input-div">
            <input
              className="normal-input"
              placeholder="Client ID here"
              value={email}
              onChange={(event) => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
